import { OrganisationDocumentCollectionResource } from '@/models/organisationDocuments/OrganisationDocumentCollectionResource'
import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'

export class ImplementationCreateRequest {
  public project_id: number
  public process_ids: number[] = []
  public template_id: number | null

  constructor(document: OrganisationDocumentCollectionResource | OrganisationDocumentResource) {
    this.project_id = document.project_info.id
    this.template_id = document.id
  }
}
